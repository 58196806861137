<template>
  <router-view />
  <BaseNotifications></BaseNotifications>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { useAppNav } from "@modernary/mui/components/common";
import { routeNames } from "@/routes";

const route = useRoute();
const { setNavigationItems } = useAppNav();

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "break-after-avoid",
    showLabel: false,
    items: [{ label: "Dashboard", name: routeNames.home }],
  },
  {
    label: "Project Management",
    className: "",
    items: [
      { label: "Contracts", name: routeNames.contracts },
      { label: "Grants", name: routeNames.grants },
      // { label: "Systems", name: routeNames.systems },
      { label: "Projects", name: routeNames.projects },
    ],
  },
]);
</script>
