<template>
  <LayoutAppContent>
    <div class="h-full overflow-auto" :class="{ 'bg-neutral-100': isAuthenticated }">
      <div class="">
        <!-- content -->

        <!-- <div class="mx-auto mb-5 max-w-5xl px-2" v-if="isInternal && analyticsNavItem.items?.length > 0">
          <div class="mx-3 sm:mx-5 md:mx-8 xl:mx-0">
            <h2 class="text-sm font-medium text-neutral-500">Analytics</h2>
            <ul role="list" class="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-6">
              <template v-for="item in analyticsNavItem.items" :key="item.name">
                <SkeletonButton v-if="analyticsNavItem.isAuthPending" class="col-span-1" />
                <li class="col-span-1 flex rounded-md shadow-sm" v-if="analyticsNavItem.isAuthorized">
                  <router-link
                    :to="{ name: item.name }"
                    class="flex flex-1 items-start justify-between rounded-md border border-neutral-200 bg-white text-neutral-900 transition-all hover:border-sky-200 hover:bg-sky-100 hover:text-sky-900 focus:border-sky-200 focus:bg-sky-100 focus:text-sky-900"
                  >
                    <div class="flex-1 px-4 py-2 text-sm">
                      <div class="font-medium">{{ item.label }}</div>
                      <p class="text-neutral-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div> -->

        <div class="p-5 text-center sm:pt-10" v-if="isAuthenticated">
          <h1
            class="relative inline-block w-full rounded-lg border-4 border-neutral-300 bg-white p-12 text-center sm:w-1/2"
          >
            <FaceSmileIcon class="mx-auto h-12 w-12 text-neutral-400"></FaceSmileIcon>
            <span class="mt-4 block text-xl font-medium text-neutral-900">Welcome {{ userRef.firstName }}!</span>
          </h1>
        </div>

        <div class="mx-auto mt-10 grid grid-cols-1 gap-10 xl:grid-cols-2" v-if="isExternalAuthenticated">
          <!-- Organizations -->

          <!-- Facilities -->
        </div>

        <!-- Home features content for auth'd and unauth'd users alike. We toggle widgets based on auth status. (and eventually, user profile config) -->
        <div
          v-if="!isAuthenticated && !isInternal"
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">
                Insight
                <!-- <span class="font-semibold">(Sustainability Management and Reporting Tool)</span> -->
              </h1>
              <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
                <span class="font-semibold"><LoginButton class="hover:underline" /></span>
                <!-- or
                <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton> -->
                to get started
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>Insight is a grant, contract, and project tracking app.</p>
              </div>
            </div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
              <!-- <BaseButton
                variant="priority"
                class="w-full sm:w-auto"
                label="Register"
                @click.prevent="() => $authn.redirectToRegister()"
              ></BaseButton> -->
            </div>
          </div>
        </div>
      </div>
      <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
        <MdrnAttribution />
      </footer>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, NoneFoundFlag, SkeletonButton, useAppNav } from "@modernary/mui/components/common";
import { LoginButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { useAuthorization } from "@modernary/mui/utils/authorization";
import { noLabelSchema } from "@modernary/mui/utils/helpers";

import { ref, computed, onMounted, watch, defineComponent } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";
// import { getOrganizations } from "@/views/organization/organizationApi";
// import { getFacilitySites } from "@/views/facilitySite/facilitySiteApi";
import { createAccessRequest } from "@/views/accessRequest/accessRequestApi";
import { enums } from "@/constants/enums";

export default defineComponent({
  setup() {
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();

    const isBureauAR = new AuthRequest();
    isBureauAR.roleName = "Bureau";

    const isHealthDistrictAR = new AuthRequest();
    isHealthDistrictAR.roleName = "HealthDistrict";

    const isBureau = ref(false);
    const isHealthDistrict = ref(false);
    authorizationClient
      .isAuthorizedArray([isBureauAR, isHealthDistrictAR])
      .then(([isBureauResult, isHealthDistrictResult]) => {
        isBureau.value = isBureauResult;
        isHealthDistrict.value = isHealthDistrictResult;
      }, handlePreempts);

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    const isBureauOrHd = computed(() => isBureau.value || isHealthDistrict.value);
    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: any) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);
    // const { recyclingCategories, facilitySiteTypes } = enums;

    // const {
    //   dataRef: organizations,
    //   // statusRef,
    //   // setStatus,
    //   exec: execGetOrganizations,
    //   isStatusPending: isGetOrganizationsPending,
    //   isStatusSuccess: isGetOrganizationsSuccess,
    // } = useApi(getOrganizations, {
    //   initialData: [],
    //   responseAdapter: (response) => {
    //     const reportingYear = new Date().getFullYear() - 1;

    //     response.data._items.forEach((org: any) => {
    //       //TODO:TS
    //       org.currentReport = org.recyclingReports._items.find((x: any) => x.year === reportingYear); //TODO:TS
    //     });

    //     return response.data;
    //   },
    // });

    // const {
    //   dataRef: facilitySites,
    //   // statusRef,
    //   // setStatus,
    //   exec: execGetFacilitySites,
    //   isStatusPending: isGetFacilitySitesPending,
    //   isStatusSuccess: isGetFacilitySitesSuccess,
    // } = useApi(getFacilitySites, {
    //   initialData: [],
    //   responseAdapter: (response) => {
    //     const reportingYear = new Date().getFullYear() - 1;

    //     // response.data._items.forEach((org) => {
    //     //   org.currentReport = org.recyclingReports._items.find((x) => x.year === reportingYear);
    //     // });

    //     return response.data;
    //   },
    // });

    // watch(
    //   isAuthenticated,
    //   (newValue) => {
    //     if (newValue) {
    //       execGetOrganizations({ expand: ["RecyclingReports"] });
    //       execGetFacilitySites();
    //     }
    //   },
    //   { immediate: true },
    // );

    // const rrAccessRequest = ref({ entityName: "", requestText: "" });
    // rrAccessRequest.value.entityName = "Organization";

    // const drAccessRequest = ref({ entityName: "", requestText: "" });
    // drAccessRequest.value.entityName = "Facility Site";

    // const formSubmissionCountRef = ref(0); //Used to "reset" the form field's state between submissions.
    // const { exec: execCreateAccessRequest, isStatusPending } = useApi(createAccessRequest, {
    //   initialData: { entityName: "", requestText: "" },
    //   responseAdapter: (response) => {
    //     if (response.data.wasRequestSubmitted) {
    //       rrAccessRequest.value.requestText = "";
    //       drAccessRequest.value.requestText = "";
    //       ++formSubmissionCountRef.value;
    //     }

    //     return response.data;
    //   },
    // });

    // const handleSubmit = (data: any) => {
    //   //TODO:TS
    //   return execCreateAccessRequest(data);
    // };

    return {
      // handleSubmit,
      noLabel,
      isExternalAuthenticated,
      isInternal,
      isBureauOrHd,
      // isGetOrganizationsPending,
      // isGetOrganizationsSuccess,
      // organizations,
      userRef,
      isAuthenticated,
      // drAccessRequest,
      // rrAccessRequest,
      // isStatusPending,
      // formSubmissionCountRef,
      // recyclingCategories,
      BaseButton,
      // isGetFacilitySitesPending,
      // isGetFacilitySitesSuccess,
      // getFacilitySites,
      // facilitySites,
      // facilitySiteTypes,
    };
  },
  components: {
    SkeletonButton,
    BaseButton,
    // LayoutAppContent,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,

    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
  },
});
</script>
