import { createApp } from "vue";
import "./styles/tailwind.css";
import "@modernary/mui/dist/style.css";
// import "@modernary/ui-components/style.css";
import App from "./App.vue";
import { routes } from "./routes";
import { createRouter, createWebHistory } from "vue-router";
import Notifications from "notiwind";
import InfiniteLoading from "v3-infinite-loading";
import { createProPlugin, inputs } from "@formkit/pro";
import { plugin, defaultConfig } from "@formkit/vue";
import { createPinia } from "pinia";
import { apiHosts } from "@/api/constants/apiHosts";

import { useAppSettingsStore } from "@modernary/mui/components/common";
import { bffAuthPlugin, IBffOptions } from "@modernary/mui/utils/authentication";
import { authzPlugin, AuthorizationClientConfig } from "@modernary/mui/utils/authorization";
import { useIdentityConfigStore } from "@modernary/mui/utils/identity";
import { default as mui } from "@modernary/mui";

import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { formKitConfig } from "@/formkit.config";

// TODO: Get import from ui-c working.
// import { formKitConfig } from "@modernary/mui/formkit.config";

const app = createApp(App);

/**
 * Specify plugins to load from the /plugins directory
 * Pass file names without any extension like so
 *
 * @example
 *
 * loadPlugins(['vue-fontawesome'])
 */
// loadPlugins([]);

/**
 * Automatically imports and registers base components
 * Make sure you have at least one Base components in components/base directory
 */
// registerBaseComponents(app);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  const appSettingsStore = useAppSettingsStore();

  if (to.meta.title) {
    document.title = appSettingsStore.pageTitleBase + " - " + to.meta.title;
  } else {
    document.title = appSettingsStore.pageTitleBase;
  }
});

// Register 3rd-party components used in ui-components
app.component("infinite-loading", InfiniteLoading);

const pro = createProPlugin("fk-53688fd8db", inputs);
formKitConfig.plugins?.push(pro);
if (formKitConfig.icons)
  formKitConfig.icons.checkCircleIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" /></svg>`;

const pinia = createPinia();
app.use(pinia);
// import pinia plugins.

// App Settings - This must happen after we've created Pinia, and called app.use
const appSettingsStore = useAppSettingsStore();
appSettingsStore.agencyName = "Insight";
appSettingsStore.agencyLogoSrc = new URL(`/images/hibiscus.svg`, import.meta.url).href;
appSettingsStore.pageTitleBase = "Insight";
appSettingsStore.showSimpleNav = true;
appSettingsStore.baseURL = import.meta.env.VITE_INSIGHT_API_BASE_URL;
appSettingsStore.apiHost = apiHosts.INSIGHT;
appSettingsStore.systemIdentifier = "INSIGHT";
appSettingsStore.viteBaseUrl = import.meta.env.BASE_URL;

const identityConfigStore = useIdentityConfigStore();
identityConfigStore.setBaseURL(import.meta.env.VITE_IDADMIN_API_BASE_URL);
identityConfigStore.setApiHost(apiHosts.IDADMIN);

app.use(router);
app.use(plugin, defaultConfig(formKitConfig));
app.use(Notifications);

app.use(authzPlugin, {} as AuthorizationClientConfig);
app.use(mui);
app.use(bffAuthPlugin, {
  registerUrl: import.meta.env.VITE_IDADMIN_REGISTER_URL,
} as IBffOptions);
app.use(autoAnimatePlugin);
app.mount("#app");
